/*Google fonts */
.alexandria-logo {
  font-family: 'Alexandria', sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

/*General*/

.accordion-item {
  * {
    background-color: #0d1127 !important;
    box-shadow: none !important;
    color: white !important;
  }

  .accordion-body {
    &,
    * {
      background-color: white !important;
      color: black !important;
    }
  }
}

body {
  background-color: #0d1127 !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.btn:not(a) {
  line-height: 100% !important;
}

.btn {
  border: none !important;
  outline: none !important;

  &.btn-primary {
    background-color: #f00000 !important;
  }

  &.btn-secondary {
    background-color: #a00000 !important;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cursorPointer {
  cursor: pointer;
}

.legalese {
  * {
    color: white !important;
    font-size: 1rem !important;
  }
}

.logo {
  font-size: 2.5rem;
  line-height: 2.5rem;

  img {
    height: 2rem !important;
    float: left;
    max-height: 2rem !important;
    margin-right: 0.5rem;
    margin-top: 0.2rem;
  }
}

.text-danger {
  color: #f00000;
  fill: currentColor;

  & svg {
    color: #f00000;
    fill: currentColor;
  }
}

div {
  .fs-smaller {
    font-size: 80%;
  }

  .text-light2 {
    color: #aaaaaa;
  }

  &.bg-dark2 {
    background-color: rgba(50, 50, 50, 1) !important;
  }

  &.suspicious {
    background-color: #f00000 !important;

    .text-light2 {
      color: #dfefef;
    }

    div {
      --bs-table-color-type: none !important;
      --bs-table-color-state: none !important;
      --bs-table-bg-state: none !important;
      background-color: rgba(240, 0, 0, 1) !important;
    }
  }

  &.valid {
    td {
      --bs-table-color-type: none !important;
      --bs-table-color-state: none !important;
      --bs-table-bg-state: none !important;
      background-color: rgba(var(--bs-success-rgb), 0.25) !important;
    }
  }
}

@media screen and (max-width: 576px) {
  .logo {
    font-size: 1.5rem !important;
    line-height: 1.5rem !important;

    img {
      height: 1.25rem !important;
      float: left;
    }
  }
}
